<template>
  <div class="basic_info2">
    <form @submit="$root.postData">
      <div class="framing-x bg-content">
        <article class="article">
          <fieldset>
            <label>{{$root.$data.BasicInfo1.name}}，請問你今年幾歲：</label>
            <div class="position-relative">
              <!--
              <input type="number" name="basicInfo[age]" placeholder="請填入歲數" min="18" max="66" list="agesDatalis" class="form-control form-control-blue pr-5" v-model="$root.$data.BasicInfo2.age" :required="required.age" />
              <datalist id="agesDatalis">
                <option v-for="age in ages" :value="age" :key="age" />
              </datalist>
              <span class="form-control-append">歲</span>
              -->
              <select name="age" class="custom-select form-control form-control-blue" :class="{'text-light': $root.$data.BasicInfo2.age == ''}" v-model="$root.$data.BasicInfo2.age" :required="required.age">
                <option value="" disabled>請填入歲數</option>
                <option v-for="age in ages" :value="age" :key="age">{{ age }}歲</option>
              </select>
            </div>
          </fieldset>

          <fieldset>
            <label>請問你的居住所在地：</label>
            <select name="location" class="custom-select form-control form-control-blue" :class="{'text-light': $root.$data.BasicInfo2.location == ''}" v-model="$root.$data.BasicInfo2.location" :required="required.location">
              <option value="" disabled>請選擇</option>
              <option v-for="(location, index) in locations" :value="location" :key="index">{{ location }}</option>
            </select>
          </fieldset>

          <fieldset>
            <label>請問你的職業：</label>
            <select name="career" class="custom-select form-control form-control-blue" :class="{'text-light': $root.$data.BasicInfo2.career == ''}" v-model="$root.$data.BasicInfo2.career" @change="onCareerChanged" :required="required.career">
              <option value="" disabled>請選擇</option>
              <option v-for="(career, index) in careers" :value="career" :key="index">{{ career }}</option>
            </select>
          </fieldset>

          <fieldset>
            <label>請問你的科系 (學生填寫)：</label>
            <select name="education" class="custom-select form-control form-control-blue" :class="{'text-light': $root.$data.BasicInfo2.education == ''}" v-model="$root.$data.BasicInfo2.education" :required="required.education">
              <option value="" disabled>請選擇</option>
              <option v-for="(education, index) in educations" :value="education.title" :key="index">{{ education.title }}</option>
            </select>
          </fieldset>

          <fieldset>
            <label>請問你目前這份工作做多久了：</label>
            <select name="career_time" class="custom-select form-control form-control-blue" :class="{'text-light': $root.$data.BasicInfo2.career_time == ''}" v-model="$root.$data.BasicInfo2.career_time" :required="required.career_time">
              <option value="" disabled>請選擇</option>
              <option v-for="(career_time, index) in career_times" :value="career_time" :key="index">{{ career_time }}</option>
            </select>
          </fieldset>

          <fieldset>
            <label>請問你的婚姻狀況：</label>
            <div class="row">
              <div class="col-12 col-lg-6">
                <label class="form-radio text-center mb-lg-0">
                  <input type="radio" name="marriage" value="已婚" v-model="$root.$data.BasicInfo2.marriage" :required="required.marriage" />
                  <span class="form-radio-value">已婚</span>
                </label>
              </div>
              <div class="col-12 col-lg-6">
                <label class="form-radio text-center mb-0">
                  <input type="radio" name="marriage" value="未婚" v-model="$root.$data.BasicInfo2.marriage" :required="required.marriage" />
                  <span class="form-radio-value">未婚</span>
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <label>Email：</label>
            <div class="text-subtle text-light mb-2 mr-n3">
              寄送分析報告與免費職業適性預約專屬代碼！
            </div>
            <input type="email" name="email" placeholder="請填入電子信箱" class="form-control form-control-blue pr-5" v-model="$root.$data.BasicInfo2.email" :required="required.email" />
          </fieldset>

          <fieldset>
            <label>行動電話：</label>
            <div class="text-subtle text-light mb-2">
              如有留下電話，會有各地區專屬分析師與你一對一分析就業狀況！
            </div>
            <input type="text" name="mobile" placeholder="請填入電話號碼" class="form-control form-control-blue pr-5" v-model="$root.$data.BasicInfo2.mobile" />
          </fieldset>
        </article>
      </div>

      <div class="framing-x framing-y">
        <button class="btn btn-primary w-100 footer-button" @click="next">下一頁</button>
      </div>
    </form>
  </div>
</template>

<script>
import { locations, careers, career_times, educations, ages } from '../data/basic_info.js'

export default {
  data() {
    return {
      ages: ages,
      locations: locations,
      careers: careers,
      career_times: career_times,
      educations: educations,
    }
  },
  mounted() {
    this.$root.$data.BasicInfo2.name = this.$root.$data.BasicInfo1.name;
    this.$root.$data.BasicInfo2.gender = this.$root.$data.BasicInfo1.gender;
  },
  computed: {
    required() {
      let required = {};
      this.$root.$data.requiredInputs.BasicInfo2.forEach((requiredColumn) => {
        required[requiredColumn] = true;
      })
      return required;
    }
  },
  methods: {
    onCareerChanged(e) {
      if (e.currentTarget.value == '學生') {
        this.$root.$data.requiredInputs.BasicInfo2.splice(0, 0, 'education');
      }
      else {
        let index = this.$root.$data.requiredInputs.BasicInfo2.indexOf('education');
        if (index != -1) this.$root.$data.requiredInputs.BasicInfo2.splice(index, 1);
      }
    },
    next(e) {
      if (!this.$root.reportFormValidity(document.querySelector('form'))) {
        e.preventDefault();
        return false;
      }
    }
  }
}
</script>
