const locations = [
  "基隆",
  "台北",
  "新北",
  "桃園",
  "新竹",
  "苗栗",
  "台中",
  "彰化",
  "南投",
  "雲林",
  "嘉義",
  "台南",
  "高雄",
  "屏東",
  "宜蘭",
  "花蓮",
  "台東",
  "澎湖",
  "金門",
  "馬祖"
];

const careers = [
  "經營/公司管理者",
  "行政/總務",
  "財務",
  "金融/保險",
  "客服/門市",
  "資訊/軟體/系統",
  "技術/維修/操作",
  "傳播/娛樂/藝術",
  "物流/運輸/倉儲",
  "醫療",
  "美容/保健",
  "清潔/家事/保姆",
  "業務/貿易/銷售",
  "廣告/設計",
  "公關",
  "工程/研發/生技",
  "品管/製造/環衛",
  "營建/施作",
  "製圖",
  "教育/學術/研究",
  "旅遊/餐飲/休閒",
  "保全/消防/警衛",
  "軍警人員",
  "學生",
  "家管",
  "農林漁牧相關",
  "待業中",
  "其他"
];

const career_times = [
  "1年內",
  "1~3年",
  "3~5年",
  "5~10年",
  "10~15年",
  "15年以上",
  "其他"
];

const educations = [
  { title: "資訊類", value: "" },
  { title: "工程類", value: "" },
  { title: "醫藥衛生類", value: "" },
  { title: "生命科學類", value: "" },
  { title: "生物資源類", value: "" },
  { title: "地球與環境類", value: "" },
  { title: "建築設計類", value: "" },
  { title: "藝術類", value: "" },
  { title: "社會心理類", value: "" },
  { title: "大眾傳播類", value: "" },
  { title: "外語類", value: "" },
  { title: "文史哲學類", value: "" },
  { title: "教育類", value: "" },
  { title: "法政類", value: "" },
  { title: "管理類", value: "" },
  { title: "財經類", value: "" },
  { title: "數理化類", value: "" },
  { title: "其他", value: "" }
];

const ages = [...Array(66 - 18).keys()].map(n => { return n + 18 });

export { locations, careers, career_times, educations, ages }
